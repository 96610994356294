import {
  ChangeDetectorRef,
  Component,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { IComboBase } from '../interfaces/base/base.interface';
import { VacationService } from './services/vacation.service';
import { VacationRequestDTO } from '../interfaces/vacation.interface';
import { DialogService } from 'primeng/dynamicdialog';
import { ModalLoadingComponent } from '../shared/modals/modal-loading/modal-loading.component';
import { ModalSuccessComponent } from '../shared/modals/modal-success/modal-success.component';
import { ModalRejectComponent } from '../shared/modals/modal-reject/modal-reject.component';
import { Router } from '@angular/router';
import { ModalInfoComponent } from './components/modal-info/modal-info.component';
import { TypeVacationService } from '../services/type-vacation.service';
import { EmployeeService } from '../services/employee.service';

@Component({
  selector: 'app-vacation',
  templateUrl: './vacation.component.html',
  styleUrls: ['./vacation.component.scss'],
})
export class VacationComponent {
  userData: any = null;
  employeeData: any;
  startDate: Date | null = null;
  endDate: Date | null = null;
  minDate: Date | null = null;
  maxStartDate: Date | null = null;
  maxEndDate: Date | null = null;
  tooltipVisible: string | null = null;

  vacationTypes: IComboBase[] = [];
  selectedOptionKey: string | null = null;

  accumulatedDays: number = 0;
  usedDaysLaw: number = 0;
  accumulatedBonusDays: number = 0;
  usedBonusDays: number = 0;
  selectedVacationType: any;
  filteredVacationTypes: IComboBase[] = [];

  totalDays: number = 0;

  transferableDays = Math.max(
    0,
    this.accumulatedDays - this.totalDays
  );


  startDateEmployee: Date | null = null;

  selectedDates: Date[] = [];
  message = '';
  confirmationChecked = false;

  isDisabled: boolean = true;

  constructor(
    private vacationService: VacationService,
    private employeeService: EmployeeService,
    private typeVacationService: TypeVacationService,
    private dialogService: DialogService,
    private cdr: ChangeDetectorRef,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.resetForm();
    this.loadEmployeeData();

    const userDataString = localStorage.getItem('access');
    if (userDataString) {
      this.userData = JSON.parse(userDataString);
    }

    this.typeVacationService.comboTypeVacation().subscribe({
      next: (data) => {
        this.vacationTypes = data;
        this.filterVacationTypesBasedOnSeniority();
      },

      error: (err) => {
        console.error('Error al obtener la lista de tipos de vacaciones:', err);
      },
    });
  }

  ngAfterViewInit(): void {
    this.loadEmployeeData();
  }

  loadEmployeeData(): void {
    this.employeeService.employeeData$.subscribe((data) => {
      if (data != null) {
        this.employeeData = data;
        console.log('this.employeeData ', this.employeeData);

        if (this.employeeData && this.employeeData.startDate) {
          const [day, month, year] = this.employeeData.startDate.split('/');
          this.startDateEmployee = new Date(`${year}-${month}-${day}`);
          this.filterVacationTypesBasedOnSeniority();

          this.accumulatedDays = this.employeeData.accumulatedDays;
          this.accumulatedBonusDays = this.employeeData.accumulatedBonusDays;
          this.usedBonusDays = this.employeeData.usedBonusDays;
          this.usedDaysLaw = this.employeeData.usedDays;

          console.log("datos: " , this.accumulatedDays, this.accumulatedBonusDays, this.usedBonusDays, this.usedDaysLaw);
          
        } else {
          console.error('employeeData o startDate es null o undefined');
        }
        this.cdr.detectChanges(); 
      }
    });
  }
  validateForm() {
    this.isDisabled = !(
      this.selectedVacationType &&
      this.startDate &&
      this.endDate &&
      this.confirmationChecked
    );

    console.log('VALIDACION: ', this.isDisabled);
  }

  filterVacationTypes(event: any): void {
    const query = event.query.toLowerCase();
    const today = new Date();
    const oneYearAgo = new Date(today);
    oneYearAgo.setFullYear(today.getFullYear() - 1);

    this.filteredVacationTypes = this.vacationTypes.filter(
      (option) =>
        option.text.toLowerCase().includes(query) &&
        (this.startDateEmployee &&
        this.startDateEmployee.getTime() <= oneYearAgo.getTime()
          ? true
          : option.key == '1')
    );
  }

  filterVacationTypesBasedOnSeniority(): void {
    const today = new Date();
    const oneYearAgo = new Date(today);
    oneYearAgo.setFullYear(today.getFullYear() - 1);

    if (
      this.startDateEmployee &&
      this.startDateEmployee.getTime() <= oneYearAgo.getTime()
    ) {
      this.filteredVacationTypes = this.vacationTypes;
    } else {
      this.filteredVacationTypes = this.vacationTypes.filter(
        (type) => type.key == '1'
      );
    }
  }

  onChangeInput(event: any): void {
    this.selectedVacationType = null;
  }

  onSelect(event: any): void {
    this.selectedVacationType = event.value ?? null;
    this.selectedOptionKey = this.selectedVacationType?.key || null;
    console.log('Tipo de vacaciones seleccionada:', this.selectedOptionKey);
    this.validateForm();
  }

  // Método para manejar la selección de rango
  // onRangeSelect(): void {
  //   if (Array.isArray(this.selectedDates) && this.selectedDates.length === 2) {
  //     this.startDate = this.selectedDates[0];
  //     this.endDate = this.selectedDates[1];
  //   } else {
  //     this.selectedDates = [];
  //     this.startDate = this.endDate = null;
  //   }
  // }

  // Actualiza el rango de fechas al cambiar individualmente las fechas de inicio o fin
  updateRange(): void {
    if (this.startDate && this.endDate) {
      this.selectedDates = [this.startDate, this.endDate];
    } else {
      this.selectedDates = [];
    }
  }

  openTooltipModal(title: string, description: string) {
    this.dialogService.open(ModalInfoComponent, {
      closable: false,
      width: '300px',
      data: {
        title: title,
        description: description,
      },
    });
  }

  onRegister() {
    const loadingRef = this.dialogService.open(ModalLoadingComponent, {
      closable: false,
    });

    const randomNumber = Math.floor(100000 + Math.random() * 900000);
    const solicitudeCode = `VACATION-${randomNumber}`;
    const request: VacationRequestDTO = {
      companyId: this.userData?.companyId,
      employeeId: this.userData?.employeeId,
      solicitudeCode: solicitudeCode,
      typeVacationId: this.selectedVacationType.key,
      breakStartDate: this.startDate!.toISOString().split('.')[0],
      breakEndDate: this.endDate!.toISOString().split('.')[0],
      detailSolicitudeDescription: this.message,
    };

    const formData = new FormData();

    formData.append('companyId', request.companyId!);
    formData.append('employeeId', request.employeeId!);
    formData.append('solicitudeCode', request.solicitudeCode);
    formData.append('typeVacationId', request.typeVacationId!);
    formData.append('breakStartDate', request.breakStartDate!);
    formData.append('breakEndDate', request.breakEndDate!);
    formData.append(
      'detailSolicitudeDescription',
      request.detailSolicitudeDescription!
    );

    this.vacationService.createVacation(formData).subscribe({
      next: (response: any) => {
        loadingRef.close();
        const successMessage = response.message;
        const ref = this.dialogService.open(ModalSuccessComponent, {
          closable: true,
          data: {
            text: successMessage,
            title: '¡Solicitud enviada!',
            icon: 'pi pi-check-circle',
            showButton: true,
            buttonText: 'Ver solicitudes',
            buttonAction: () => {
              this.navigateToSolicitudes();
            },
          },
        });
        console.log('response', successMessage);
        this.resetForm();
        this.loadEmployeeData();
      },
      error: (err) => {
        loadingRef.close();
        const ref = this.dialogService.open(ModalRejectComponent, {
          closable: true,
          data: {
            text:
              err.error?.error ||
              'Lo sentimos, ocurrió un error. Por favor, inténtelo nuevamente.',
            title: '¡Lo sentimos!',
            showButton: true,
          },
        });
        this.resetForm();
      },
    });
  }

  navigateToSolicitudes() {
    this.router.navigate(['/dashboard/solicitude']);
  }

  resetForm() {
    this.selectedVacationType = null;
    this.startDate = null;
    this.endDate = null;
    this.confirmationChecked = false;
    this.message = '';

    this.selectedDates = [];
    this.selectedOptionKey = null;

    this.validateForm();
  }

  onStartDateChange(selectedStartDate: Date): void {
    if (selectedStartDate) {
      this.startDate = selectedStartDate;
      this.maxEndDate = new Date(selectedStartDate);
      this.maxEndDate.setDate(this.maxEndDate.getDate() + 14);

      if (this.endDate && this.endDate < this.startDate) {
        this.endDate = null;
      }

      this.syncSelectedDates();
    } else {
      this.maxEndDate = null;
    }
  }

  onEndDateChange(selectedEndDate: Date): void {
    if (selectedEndDate) {
      if (this.startDate && selectedEndDate < this.startDate) {
        this.endDate = null;
      } else {
        this.endDate = selectedEndDate;
      }

      this.syncSelectedDates();
    }
  }

  onRangeSelect(): void {
    if (Array.isArray(this.selectedDates) && this.selectedDates.length === 2) {
      this.startDate = this.selectedDates[0];
      this.endDate = this.selectedDates[1];
      if (this.startDate) {
        this.maxEndDate = new Date(this.startDate);
        this.maxEndDate.setDate(this.maxEndDate.getDate() + 14);
      } else {
        this.maxEndDate = null;
      }
    } else {
      this.startDate = this.endDate = null;
    }
  }

  syncSelectedDates(): void {
    if (this.startDate && this.endDate) {
      this.selectedDates = [this.startDate, this.endDate];
    } else {
      this.selectedDates = [];
    }
  }
}
